
import { defineComponent } from "vue";
import { MdSidebar, IMenuItem, ISidebarFAB } from "@/components/md/MdSidebar";

export default defineComponent({
  name: "BotSidebar",
  components: {
    MdSidebar,
  },
  data() {
    const prefix = "/bot/" + this.$route.params.chatbotId;
    return {
      menu: [
        {
          icon: "dashboard",
          title: "Übersicht",
          href: prefix + "/dashboard",
        },
        {
          icon: "timeline",
          title: "Versionen",
          href: prefix + "/versions",
        },
        {
          icon: "inbox",
          title: "Posteingang",
          href: "/inbox/" + this.$route.params.chatbotId + "/all",
        },
        {
          headline: "Entwicklung",
        },
        {
          icon: "merge_type",
          title: "Bot Builder",
          href: prefix + "/flows",
        },
        {
          icon: "travel_explore",
          title: "Entitäten",
          href: prefix + "/entites",
        },
        {
          icon: "perm_media",
          title: "Mediathek",
          href: prefix + "/files",
        },
        {
          icon: "list",
          title: "Logs",
          href: prefix + "/logs",
        },
        {
          headline: "Optimierung",
        },
        {
          icon: "psychology",
          title: "Verständnis",
          href: prefix + "/understanding",
        },
        {
          icon: "model_training",
          title: "Training",
          href: prefix + "/training",
        },
        {
          icon: "multiline_chart",
          title: "Analyse",
          href: prefix + "/analysis",
        },
        {
          headline: "Support",
        },
        {
          icon: "question_answer",
          title: "FAQ",
          href: prefix + "/faqs",
        },
        {
          headline: "Marketing",
        },
        {
          icon: "supervisor_account",
          title: "Zielgruppen",
          href: prefix + "/audiences",
        },
        {
          icon: "send",
          title: "Broadcasts",
          href: prefix + "/broadcasts",
        },
        {
          divider: true,
        },
        {
          icon: "extension",
          title: "Kanäle",
          href: prefix + "/channels",
        },
        {
          icon: "wysiwyg",
          title: "WhatsApp Templates",
          href: prefix + "/templates",
        },
        {
          icon: "security",
          title: "Datenschutz-Center",
          href: prefix + "/privacy",
        },
        {
          icon: "settings",
          title: "Einstellungen",
          href: prefix + "/settings",
        },
      ] as IMenuItem[],
    };
  },
});
