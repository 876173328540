import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_uni_picker = _resolveComponent("uni-picker")!

  return (_openBlock(), _createBlock(_component_uni_picker, {
    "type-name": "Variable",
    data: _ctx.variables,
    x: _ctx.x,
    y: _ctx.y,
    anchor: _ctx.anchor,
    target: _ctx.target,
    modelValue: _ctx.variable,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.variable) = $event)),
    onSelect: _ctx.select,
    onClose: _ctx.close
  }, null, 8, ["data", "x", "y", "anchor", "target", "modelValue", "onSelect", "onClose"]))
}