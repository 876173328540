
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "MdTooltip",
  props: {
    text: String,
    position: {
      type: String as PropType<"bottom" | "top" | "left" | "right">,
      default: "bottom",
    },
  },
  data() {
    return {
      show: false,
    };
  },
});
