import { fetchCloudRun } from "@/api/Helper";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";

/**
 * Creates a new conversation (only WhatsApp).
 * @param name of the name conversation
 * @param phonenumber of the conversation (valid whatsapp phonenumber)
 */
export const createConversation = async (
  name: string,
  phonenumber: string
): Promise<string> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  const result = await fetchCloudRun(
    process.env.VUE_APP_BASE_URL +
      "/conversation/chatbots/" +
      chatbotId +
      "/conversations",
    "POST",
    {},
    {
      name,
      phonenumber,
      channel: "whatsapp",
      channel_id: "",
      channel_chat_id: phonenumber,
    }
  );
  return result.id;
};
