
import { defineComponent } from "vue";
import MdInputMixin from "./MdInputMixin";
import { uuid } from "@/utils/MdUuid";

export default defineComponent({
  name: "MdTextarea",
  mixins: [MdInputMixin],
  props: {
    id: {
      type: String,
      default: () => uuid("textarea"),
    },
  },
});
