export abstract class Validator {
  protected value: string | number = "";
  protected errorMessage = "";
  private readonly required: boolean;

  constructor(required?: boolean) {
    this.required = !!required;
  }

  protected abstract validate(): boolean;

  public isValid(): boolean {
    if (this.isRequired() && !this.hasValue()) {
      this.errorMessage = "Dieses Feld muss ausgefüllt werden.";
      return false;
    } else if (!this.isRequired() && !this.hasValue()) {
      return true;
    }
    const valid = this.validate();
    if (valid) this.errorMessage = "";
    return valid;
  }

  public getErrorMessage(): string {
    return this.errorMessage;
  }

  protected hasValue(): boolean {
    return !!this.value && this.value.toString().length > 0;
  }

  public setValue(value: string | number): void {
    this.value = value;
  }

  public isRequired(): boolean {
    return this.required;
  }

  public clone(): Validator {
    return new (this.constructor as any)(this.required);
  }
}
