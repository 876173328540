
import { defineComponent } from "vue";
import MdInputMixin from "./MdInputMixin";
import { uuid } from "@/utils/MdUuid";
import { IMenuItem, MdMenu, TransitionMdMenu } from "../MdMenu";
import MdMenuMixin from "../MdMenu/MdMenuMixin";

export default defineComponent({
  name: "MdSelect",
  components: {
    MdMenu,
    TransitionMdMenu,
  },
  mixins: [MdInputMixin, MdMenuMixin],
  props: {
    id: {
      type: String,
      default: () => uuid("select"),
    },
    titleValue: {
      type: String,
      default: "",
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:titleValue", "update:modelValue", "focus", "blur", "change"],
  data() {
    return {
      menuVisible: false,
      title: "",
      results: [] as IMenuItem[],
    };
  },
  mounted() {
    this.updateTitle();
  },
  watch: {
    modelValue: {
      handler() {
        this.updateTitle();
      },
    },
  },
  methods: {
    updateTitle() {
      if (this.modelValue) {
        const item: IMenuItem | undefined = this.items.find((el: IMenuItem) => {
          return el.value === this.modelValue;
        });
        if (item) this.onChange(item.value, item);
      }
    },
    onFocus() {
      this.menuVisible = true;
      if (this.title.trim().length <= 0 && this.results.length <= 0)
        this.results = this.items;
    },
    onBlur() {
      setTimeout(() => {
        this.menuVisible = false;
      }, 50);
      if (!this.hasValue) {
        this.title = "";
        this.resetValues();
      }
    },
    onChange(value, item: IMenuItem) {
      //overrides default method from MdInputMixin
      (this.$refs["input"] as HTMLInputElement).value = item.label;
      this.search();
      this.hasValue = value.toString().length > 0;
      this.$emit("update:modelValue", value);
      this.$emit("change");
      this.title = item.label;
    },
    resetValues() {
      this.$emit("update:modelValue", "");
      this.$emit("update:titleValue", "");
    },
    search() {
      const query = (this.$refs["input"] as HTMLInputElement).value
        .toLowerCase()
        .trim();
      if (!query || query.length <= 0) {
        this.results = this.items;
        return;
      }
      this.results = this.items.filter((item) =>
        item.label.toLowerCase().trim().includes(query)
      );
    },
  },
});
