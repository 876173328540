
import { defineComponent, PropType } from "vue";
import { MdButton } from "@/components/md/MdButton/";

export default defineComponent({
  name: "MdDatepicker",
  components: {
    MdButton,
  },
  props: {
    modelValue: {
      type: Object as PropType<Date>,
      default: new Date(),
    },
  },
  emits: ["update:modelValue", "save"],
  setup() {
    return {
      weekdays: ["M", "D", "M", "D", "F", "S", "S"],
      weekdaysShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      monthNames: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
      monthNamesShort: [
        "Jan.",
        "Feb.",
        "Mär.",
        "Apr.",
        "Mai",
        "Jun.",
        "Jul.",
        "Aug.",
        "Sep.",
        "Okt.",
        "Nov.",
        "Dez.",
      ],
    };
  },
  data() {
    return {
      visible: false,
      selectedDate: new Date(),
      currentYear: 2022,
      currentMonth: 0,
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value: Date | null) {
        if (!value) return;
        this.selectedDate = value || new Date();
        this.currentYear = this.selectedDate.getFullYear();
        this.currentMonth = this.selectedDate.getMonth();
      },
    },
    selectedDate: {
      immediate: true,
      handler(value: Date) {
        this.currentMonth = value.getMonth();
        this.currentYear = value.getFullYear();
      },
    },
  },
  methods: {
    open() {
      this.visible = true;
      document.documentElement.style.overflow = "hidden";
    },
    close() {
      this.visible = false;
      document.documentElement.style.overflow = "auto";
    },
    selectDay(day: number) {
      const date = new Date(this.selectedDate);
      date.setFullYear(this.currentYear, this.currentMonth, day);
      this.selectedDate = date;
    },
    select() {
      this.$emit("update:modelValue", this.selectedDate);
      this.$emit("save");
      this.close();
    },
    getDayIndex(day: number): number {
      let index = new Date(this.currentYear, this.currentMonth, day).getDay();
      if (index == 0) {
        index = 6;
      } else {
        index--;
      }
      return index;
    },
    nextMonth() {
      this.currentMonth++;
      if (this.currentMonth >= 12) {
        this.currentMonth = 0;
        this.currentYear++;
      }
    },
    prevMonth() {
      this.currentMonth--;
      if (this.currentMonth <= 0) {
        this.currentMonth = 11;
        this.currentYear--;
      }
    },
  },
  computed: {
    days(): number {
      return new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
    },
    today(): Date {
      return new Date();
    },
  },
});
