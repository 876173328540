import { IChat } from "@chatcaptain/types/dist/chatbot";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import {
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore";

export const getConversation = (
  id: string,
  callback: (conversation: IChat | null) => void
): Unsubscribe => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  return onSnapshot(
    doc(getFirestore(), "dialogs/" + chatbotId + "/chats/" + id),
    (conversation) => {
      if (!conversation.exists) {
        callback(null);
        return;
      }
      callback({ ...conversation.data(), id: conversation.id } as IChat);
    }
  );
};
