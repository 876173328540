
import { defineComponent } from "vue";
import { MdSidebar, IMenuItem, ISidebarFAB } from "@/components/md/MdSidebar";
import ConversationStartModal from "@/components/inbox/ConversationStartModal.vue";
import { IMdModal } from "@/components/md/MdDialog";
import { useStore } from "@/store";
import { AdminStore, InboxStore } from "@/store/constants";
import {
  IGroupResult,
  IUser,
  IUserResult,
} from "@chatcaptain/types/dist/organization";
import { useCookies } from "vue3-cookies";

export default defineComponent({
  name: "InboxSidebar",
  components: {
    ConversationStartModal,
    MdSidebar,
  },
  setup() {
    const { cookies } = useCookies();
    return {
      cookies,
      store: useStore(),
    };
  },
  computed: {
    startConversationModal(): IMdModal {
      return this.$refs.startConversationModal as IMdModal;
    },
    customViews(): IMenuItem[] {
      const prefix = "/inbox/" + this.$route.params.chatbotId;
      const views = this.store.getters[InboxStore.Getters.GET_VIEWS];
      if (!views) return [];
      const viewsHidden = JSON.parse(this.cookies.get("views_hidden")) || [];
      return views
        .filter((view) => !viewsHidden.includes(view.id))
        .map((view) => {
          return {
            title: view.name,
            icon: "view_timeline",
            href: prefix + "/" + view.id,
          } as IMenuItem;
        });
    },
    users(): IUserResult[] {
      return this.store.getters[AdminStore.Getters.GET_USERS] || [];
    },
    groups(): IGroupResult[] {
      return this.store.getters[AdminStore.Getters.GET_GROUPS] || [];
    },
    menu(): IMenuItem[] {
      const prefix = "/inbox/" + this.$route.params.chatbotId;
      const userItems: IMenuItem[] = [];
      if (this.users) {
        userItems.push(
          ...this.users.map((user) => {
            return {
              title: user.name,
              icon: "person",
              href: prefix + "/" + user.id,
            } as IMenuItem;
          })
        );
      }
      const groupItems: IMenuItem[] = [];
      if (this.groups) {
        groupItems.push(
          ...this.groups.map((group) => {
            return {
              title: group.name,
              icon: "group",
              href: prefix + "/" + group.id,
            } as IMenuItem;
          })
        );
      }
      return [
        {
          icon: "inbox",
          title: "Deine Konversationen",
          href: prefix + "/assigned",
        },
        {
          icon: "mark_unread_chat_alt",
          title: "Ungelesen",
          href: prefix + "/unread",
        },
        {
          icon: "alternate_email",
          title: "Erwähnt",
          href: prefix + "/mentioned",
        },
        {
          icon: "no_accounts",
          title: "Nicht zugewiesen",
          href: prefix + "/unassigned",
        },
        {
          icon: "schedule",
          title: "Zurückgestellt",
          href: prefix + "/snoozed",
        },
        {
          icon: "schedule",
          title: "Deine zurückgestellten",
          href: prefix + "/snoozed_own",
        },
        // {
        //   icon: "alarm_off",
        //   title: "Nicht zurückgestellt",
        //   href: prefix + "/not_snoozed",
        // },
        {
          icon: "campaign",
          title: "Offen",
          href: prefix + "/open",
        },
        {
          icon: "campaign",
          title: "Deine Offenen",
          href: prefix + "/open_own",
        },
        {
          icon: "archive",
          title: "Archiviert",
          href: prefix + "/archived",
        },
        {
          icon: "groups",
          title: "Alle",
          href: prefix + "/all",
        },
        // {
        //   headline: "Teams",
        // },
        // ...groupItems,
        // {
        //   headline: "Teammitglieder",
        // },
        // ...userItems,
        {
          headline: "Ansichten",
        },
        ...this.customViews,
        {
          divider: true,
        },
        {
          icon: "settings",
          title: "Einstellungen",
          href: prefix + "/settings/answers",
        },
      ] as IMenuItem[];
    },
  },
  data() {
    return {
      fab: {
        title: "Konversation starten",
        icon: "drive_file_rename_outline",
        onClick: this.startConversation,
      } as ISidebarFAB,
    };
  },
  methods: {
    startConversation() {
      this.startConversationModal.open();
    },
  },
});
