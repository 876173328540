
import { defineComponent, PropType } from "vue";
import MdInputField from "@/components/md/MdInput/MdInputField.vue";
import { MdDatepicker, IMdDatepicker } from "@/components/md/MdPicker";
import MdInputMixin from "@/components/md/MdInput/MdInputMixin";
import { Validator } from "@/classes/validators/Validator";
import { DateFormat } from "@/classes/DateFormat";
import { DateParser } from "@/classes/DateParser";

export default defineComponent({
  name: "MdDateField",
  components: {
    MdDatepicker,
    MdInputField,
  },
  mixins: [MdInputMixin],
  props: {
    modelValue: {
      type: [String, Object],
    },
    style: String,
    withoutIcon: Boolean,
    validator: Object as PropType<Validator>,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      dateValue: "",
      date: null as Date | null,
      datepickerDate: new Date() as Date | null,
    };
  },
  mounted() {
    if (!this.modelValue) {
      this.date = null;
    } else if (typeof this.modelValue === "string") {
      this.date = new Date(this.modelValue);
    } else if (typeof this.modelValue === "object") {
      this.date = new Date(this.modelValue);
    }
    this.$emit("update:modelValue", this.modelValue || "");
    if (this.date) {
      this.dateValue = DateFormat.formatDate(this.date);
      this.datepickerDate = this.date;
    } else {
      this.dateValue = "";
    }
  },
  watch: {
    modelValue(value: Date | string | null) {
      if (this.date == value || this.date?.valueOf() == value?.valueOf())
        return;
      if (!this.modelValue) {
        this.date = null;
        this.dateValue = "";
      } else if (typeof this.modelValue === "string") {
        this.date = new Date(this.modelValue);
      } else if (typeof this.modelValue === "object") {
        this.date = new Date(this.modelValue);
      }
      if (this.date) {
        this.dateValue = DateFormat.formatDate(this.date);
        this.datepickerDate = this.date;
      } else {
        this.dateValue = "";
      }
    },
    date: {
      handler(value: Date | string | null | undefined) {
        if (!value) return;
        if (typeof value === "string") {
          this.date = new Date(value);
          return;
        }
        this.$emit("update:modelValue", value || "");
        if (value !== this.datepickerDate) this.datepickerDate = value;
      },
    },
    dateValue(value: string) {
      try {
        const date = DateParser.parse(value);
        if (this.date !== date) this.date = date;
      } catch (err) {
        return;
      }
    },
  },
  computed: {
    iconName(): string {
      return this.withoutIcon ? "" : "calendar_month";
    },
    datepicker(): IMdDatepicker {
      return this.$refs.datepicker as IMdDatepicker;
    },
  },
  methods: {
    onDatepickerSave() {
      if (!this.datepickerDate) return;
      this.date = this.datepickerDate;
      this.dateValue = DateFormat.formatDate(this.datepickerDate);
    },
  },
});
