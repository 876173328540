
import { defineComponent } from "vue";
import { MdButton } from "@/components/md/MdButton";
import UserProfile from "./UserProfile.vue";
import { TransitionFade } from "@/components/cc/Transition";
import ChatCaptainLogo from "@/assets/images/chatcaptain-logo.svg";

export default defineComponent({
  name: "TopNav",
  components: {
    MdButton,
    TransitionFade,
    UserProfile,
  },
  props: {
    logo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ChatCaptainLogo,
      showUserProfile: false,
    };
  },
});
