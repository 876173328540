import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_top_nav = _resolveComponent("top-nav")!

  return (_openBlock(), _createBlock(_component_top_nav, null, {
    right: _withCtx(() => [
      _createVNode(_component_router_link, { to: "/admin" }, {
        default: _withCtx(() => [
          _createVNode(_component_md_button, { variant: "outlined" }, {
            default: _withCtx(() => [
              _createTextVNode("Admin-Konsole")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}