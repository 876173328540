
import { defineComponent, PropType } from "vue";
import { MdButton } from "@/components/md/MdButton/";
type PickMode = "hours" | "minutes";

export default defineComponent({
  name: "MdTimepicker",
  components: {
    MdButton,
  },
  props: {
    modelValue: {
      type: Object as PropType<Date>,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      visible: false,
      mode: "hours" as PickMode,
      selectedHour: 12,
      selectedMinute: 0,
      selectedDate: new Date(),
      currentHour: 0,
      currentMinute: 0,
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        this.selectedDate = value;
        this.selectedHour = this.selectedDate.getHours();
        this.selectedMinute =
          Math.round(this.selectedDate.getMinutes() / 5) * 5;
      },
    },
  },
  methods: {
    open() {
      this.visible = true;
      document.documentElement.style.overflow = "hidden";
    },
    close() {
      this.visible = false;
      document.documentElement.style.overflow = "auto";
    },
    selectHour(hour: number) {
      this.selectedHour = hour;
      this.mode = "minutes";
    },
    selectMinute(minute: number) {
      this.selectedMinute = minute;
    },
    select() {
      const date = new Date(this.selectedDate);
      date.setHours(this.selectedHour, this.selectedMinute, 0, 0);
      this.$emit("update:modelValue", date);
      this.selectedDate = date;
      this.close();
    },
  },
  computed: {
    degree(): number {
      if (this.mode == "hours") {
        return this.selectedHour > 12
          ? (this.selectedHour - 12) * 30
          : this.selectedHour * 30;
      } else {
        return (this.selectedMinute / 5) * 30;
      }
    },
  },
});
