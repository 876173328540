import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b00df50c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  class: "material-icons-round"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "md-menu-wrapper",
    style: _normalizeStyle({ width: _ctx.width + 'px' })
  }, [
    _createElementVNode("ul", {
      class: _normalizeClass(["md-menu", [_ctx.small ? 'small' : '', _ctx.variant]]),
      style: _normalizeStyle({ transform: 'translateY(-' + _ctx.offsetTop + 'px)' })
    }, [
      _createVNode(_TransitionGroup, {
        name: "menu-list",
        onBeforeEnter: _ctx.onBeforeEnter,
        onEnter: _ctx.onEnter,
        onLeave: _ctx.onLeave
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (items, key) => {
            return (_openBlock(), _createElementBlock("li", {
              key: key,
              class: _normalizeClass({ icon: items.icon || _ctx.iconSpace }),
              onClick: ($event: any) => (_ctx.onSelect(key))
            }, [
              (items.icon)
                ? (_openBlock(), _createElementBlock("i", _hoisted_2, _toDisplayString(items.icon), 1))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(items.label), 1)
            ], 10, _hoisted_1))
          }), 128))
        ]),
        _: 1
      }, 8, ["onBeforeEnter", "onEnter", "onLeave"])
    ], 6)
  ], 4))
}