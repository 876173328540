import { collection, getDocs, getFirestore } from "firebase/firestore";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import { IAnswer } from "@/types/inbox/answers";

/**
 * Gets all tags
 */
export const getAllAnswers = async (): Promise<IAnswer[]> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  const docs = await getDocs(
    collection(
      getFirestore(),
      "dialogs/" + chatbotId + "/settings/inbox/answers"
    )
  );
  return docs.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    } as IAnswer;
  });
};
