import {
  collection,
  getDocs,
  getFirestore,
  orderBy,
  query,
} from "firebase/firestore";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import { ICustomView } from "@/types/inbox/views";

/**
 * Gets all custom views
 */
export const getAllViews = async (): Promise<ICustomView[]> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  const docs = await getDocs(
    query(
      collection(
        getFirestore(),
        "dialogs/" + chatbotId + "/settings/inbox/views"
      ),
      orderBy("name", "asc")
    )
  );
  return docs.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    } as ICustomView;
  });
};
