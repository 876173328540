import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_sidebar = _resolveComponent("md-sidebar")!
  const _component_conversation_start_modal = _resolveComponent("conversation-start-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_md_sidebar, {
      menu: _ctx.menu,
      fab: _ctx.fab
    }, null, 8, ["menu", "fab"]),
    _createVNode(_component_conversation_start_modal, { ref: "startConversationModal" }, null, 512)
  ], 64))
}