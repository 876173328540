
import { defineComponent, PropType } from "vue";
import MdInputField from "@/components/md/MdInput/MdInputField.vue";
import { MdTimepicker, IMdTimepicker } from "@/components/md/MdPicker";
import { DateFormat } from "@/classes/DateFormat";
import MdInputMixin from "@/components/md/MdInput/MdInputMixin";

export default defineComponent({
  name: "MdTimeField",
  components: {
    MdTimepicker,
    MdInputField,
  },
  mixins: [MdInputMixin],
  props: {
    modelValue: {
      type: [String, Object],
    },
    style: String,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      dateValue: "",
      date: this.modelValue || new Date(),
    };
  },
  watch: {
    date: {
      immediate: true,
      handler(value: Date) {
        this.dateValue = DateFormat.formatTime(value);
        this.$emit("update:modelValue", value);
      },
    },
  },
  computed: {
    timepicker(): IMdTimepicker {
      return this.$refs.timepicker as IMdTimepicker;
    },
  },
});
