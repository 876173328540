import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3d4cef8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "material-icons-round"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["md-fab", [
      _ctx.color,
      _ctx.icon ? 'icon' : '',
      !_ctx.notExtended ? 'extended' : 'not-extended',
    ]]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("i", _hoisted_1, _toDisplayString(_ctx.icon), 1))
      : _createCommentVNode("", true)
  ], 2))
}