
import { defineComponent, PropType } from "vue";
import { MdNavigationDivider, MdNavigationHeadline, IMenuItem } from ".";

export default defineComponent({
  name: "MdNavigationItem",
  components: {
    MdNavigationDivider,
    MdNavigationHeadline,
  },
  props: {
    item: {
      required: true,
      type: Object as PropType<IMenuItem>,
    },
  },
});
