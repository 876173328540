import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58ae2d39"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "material-icons-round"
}
const _hoisted_2 = {
  key: 1,
  class: "material-icons-round"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["md-snackbar", [_ctx.state]])
  }, [
    (_ctx.state === 'success')
      ? (_openBlock(), _createElementBlock("i", _hoisted_1, "check_circle"))
      : (_ctx.state === 'error')
        ? (_openBlock(), _createElementBlock("i", _hoisted_2, "error"))
        : _createCommentVNode("", true),
    _createElementVNode("span", null, _toDisplayString(_ctx.content), 1)
  ], 2))
}