import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-224c3bcc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loader" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", { src: _ctx.ChatCaptainGif }, null, 8, _hoisted_3)
    ])
  ]))
}