import { ITagColor } from "@/types/inbox/tags";

const colors = {
  grey: {
    name: "Grau",
    color: "#e8eaed",
  },
  red: {
    name: "Rot",
    color: "#f28b82",
  },
  orange: {
    name: "Orange",
    color: "#fbbc04",
  },
  yellow: {
    name: "Gelb",
    color: "#fff475",
  },
  green: {
    name: "Grün",
    color: "#ccff90",
  },
  light_blue: {
    name: "Hellblau",
    color: "#cbf0f8",
  },
  blue: {
    name: "Blau",
    color: "#aecbfa",
  },
  purple: {
    name: "Lila",
    color: "#d7aefb",
  },
  brown: {
    name: "Braun",
    color: "#e6c9a8",
  },
  teal: {
    name: "Türkis",
    color: "#4DB6AC",
  },
  dark_green: {
    name: "Dunkelgrün",
    color: "#81C784",
  },
  blue_grey: {
    name: "Blaugrau",
    color: "#90A4AE",
  },
  pink: {
    name: "Pink",
    color: "#F48FB1",
  },
  lime: {
    name: "Limone",
    color: "#DCE775",
  },
  light_red: {
    name: "Hellrot",
    color: "#fbd3e0",
  },
} as { [key: string]: ITagColor };

export { colors };
