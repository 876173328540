import { store } from "@/store";
import { InboxStore } from "@/store/constants";
import { fetchCloudRun } from "@/api/Helper";
import { IChat } from "@chatcaptain/types/chatbot";

export const getLocation = async (): Promise<IChat["location"] | null> => {
  const conversationId =
    store.getters[InboxStore.Getters.GET_CURRENT_CONVERSATION_ID];
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  const response = await fetchCloudRun(
    process.env.VUE_APP_BASE_URL +
      "/inbox/chatbots/" +
      chatbotId +
      "/conversations/" +
      conversationId +
      "/getLocation",
    "GET"
  );

  return response && response.location ? response.location : null;
};
