import { ITag } from "@/types/inbox/tags";
import {
  collection,
  getDocs,
  getFirestore,
  orderBy,
  query,
} from "firebase/firestore";
import { store } from "@/store";
import { InboxStore } from "@/store/constants";

/**
 * Gets all tags
 */
export const getAllTags = async (): Promise<ITag[]> => {
  const chatbotId = store.getters[InboxStore.Getters.GET_CHATBOT_ID];
  const docs = await getDocs(
    query(
      collection(
        getFirestore(),
        "dialogs/" + chatbotId + "/settings/inbox/tags"
      ),
      orderBy("name", "asc")
    )
  );
  return docs.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    } as ITag;
  });
};
