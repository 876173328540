
import { defineComponent } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { RootStore } from "@/store/constants";
import { MdInputField } from "@/components/md/MdInput";
import { Validator } from "@/classes/validators/Validator";
import { TextValidator } from "@/classes/validators/TextValidator";
import MdAlert from "@/components/md/MdAlert/MdAlert.vue";
import { createConversation } from "@/api/inbox/conversations/create";

export default defineComponent({
  name: "ConversationStartModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdAlert,
    MdModal,
    MdButton,
    MdInputField,
  },
  data() {
    return {
      name: "",
      phonenumber: "",
    };
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  computed: {
    nameInput(): typeof MdInputField {
      return this.$refs.nameInput as typeof MdInputField;
    },
    phoneInput(): typeof MdInputField {
      return this.$refs.phoneInput as typeof MdInputField;
    },
    nameValidator(): Validator {
      return new TextValidator(true);
    },
    phoneValidator(): Validator {
      return new TextValidator(true);
    },
  },
  methods: {
    async startConversation() {
      this.nameInput.validate();
      this.phoneInput.validate();
      if (!this.nameValidator.isValid() || !this.phoneValidator.isValid())
        return;
      this.setLoadingState(true);
      try {
        await createConversation(this.name, this.phonenumber);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Konversation erstellt",
        });
      } catch (err) {
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Konversation konnte nicht gestartet werden",
        });
      }
      this.name = "";
      this.phonenumber = "";
      this.setLoadingState(false);
      this.close();
    },
  },
});
