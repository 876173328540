import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_loader = _resolveComponent("loader")!
  const _component_transition_fade = _resolveComponent("transition-fade")!
  const _component_md_snackbar = _resolveComponent("md-snackbar")!
  const _component_md_snackbar_wrapper = _resolveComponent("md-snackbar-wrapper")!
  const _component_variable_picker = _resolveComponent("variable-picker")!
  const _component_uni_picker_transition = _resolveComponent("uni-picker-transition")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.$route.meta['fullscreen'])
      ? (_openBlock(), _createBlock(_component_router_view, {
          key: 0,
          name: "topnav",
          class: _normalizeClass(["topnav-view", [
      $options.sidenavRail ? 'nav-rail' : '',
      _ctx.$route.meta['fullwidth'] ? 'fullwidth' : '',
    ]])
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (!_ctx.$route.meta['fullscreen'] && !_ctx.$route.meta['fullwidth'])
      ? (_openBlock(), _createBlock(_component_router_view, {
          key: 1,
          name: "sidenav",
          class: _normalizeClass(["sidenav-view", [$options.sidenavRail ? 'nav-rail' : '']])
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (!_ctx.$route.name || _ctx.$route.path.startsWith('/inbox/'))
      ? (_openBlock(), _createBlock(_component_router_view, {
          key: 2,
          class: _normalizeClass(["default-view", [
      $options.sidenavRail ? 'nav-rail' : '',
      _ctx.$route.meta['fullscreen'] ? 'fullscreen' : '',
      _ctx.$route.meta['fullwidth'] ? 'fullwidth' : '',
      _ctx.$route.meta['topnav'] ? 'has-topnav' : '',
    ]])
        }, null, 8, ["class"]))
      : (_openBlock(), _createBlock(_component_router_view, {
          key: _ctx.$route.path,
          class: _normalizeClass(["default-view", [
      $options.sidenavRail ? 'nav-rail' : '',
      _ctx.$route.meta['fullscreen'] ? 'fullscreen' : '',
      _ctx.$route.meta['fullwidth'] ? 'fullwidth' : '',
      _ctx.$route.meta['topnav'] ? 'has-topnav' : '',
    ]])
        }, null, 8, ["class"])),
    _createVNode(_component_transition_fade, null, {
      default: _withCtx(() => [
        ($options.showLoader)
          ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_md_snackbar_wrapper, null, {
      default: _withCtx(() => [
        ($options.snackbar)
          ? (_openBlock(), _createBlock(_component_md_snackbar, {
              key: 0,
              state: $options.snackbar.state,
              content: $options.snackbar.content
            }, null, 8, ["state", "content"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_uni_picker_transition, null, {
      default: _withCtx(() => [
        ($options.variablePicker.open)
          ? (_openBlock(), _createBlock(_component_variable_picker, {
              key: 0,
              anchor: $options.variablePicker.anchor,
              target: $options.variablePicker.target,
              modelValue: $options.variablePicker.variable,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.variablePicker.variable) = $event))
            }, null, 8, ["anchor", "target", "modelValue"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}