
import { defineComponent } from "vue";
import ChatCaptainGif from "@/assets/animations/chatcaptain.gif";

export default defineComponent({
  name: "Loader",
  data() {
    return {
      ChatCaptainGif,
    };
  },
});
