import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62809fd1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-nav" }
const _hoisted_2 = {
  key: 0,
  class: "logo-wrapper"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "slot-left" }
const _hoisted_5 = { class: "slot-right" }
const _hoisted_6 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!
  const _component_user_profile = _resolveComponent("user-profile")!
  const _component_transition_fade = _resolveComponent("transition-fade")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_ctx.logo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.ChatCaptainLogo,
            alt: "ChatCaptain"
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "left", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "right", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_md_button, {
        variant: "icon",
        icon: "bug_report",
        grey: ""
      }),
      _createVNode(_component_md_button, {
        variant: "icon",
        icon: "account_circle",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showUserProfile = !_ctx.showUserProfile))
      })
    ]),
    _createVNode(_component_transition_fade, null, {
      default: _withCtx(() => [
        (_ctx.showUserProfile)
          ? (_openBlock(), _createBlock(_component_user_profile, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}