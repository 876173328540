
import { defineComponent } from "vue";
import MdInputMixin from "./MdInputMixin";
import { uuid } from "@/utils/MdUuid";

export default defineComponent({
  name: "MdContentEditable",
  mixins: [MdInputMixin],
  emits: [
    "update:modelValue",
    "focus",
    "blur",
    "change",
    "keydown",
    "update:text",
  ],
  props: {
    id: {
      type: String,
      default: () => uuid("contenteditable"),
    },
    rows: {
      type: Number,
      default: 1,
    },
    text: String,
    multiline: {
      type: Boolean,
      default: true,
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.updateContent(this.modelValue as string);
  },
  watch: {
    modelValue(val: string) {
      if (this.getContent() !== val) this.updateContent(val);
    },
  },
  methods: {
    getContent(): string {
      return (this.$refs.input as HTMLElement).innerText;
    },
    updateContent(content?: string) {
      (this.$refs.input as HTMLElement).innerHTML = (content || "") as string;
    },
    onKeydown(event: KeyboardEvent) {
      this.$emit("keydown", event);
      const allowedKeys = [
        "ArrowUp",
        "ArrowLeft",
        "ArrowRight",
        "ArrowDown",
        "Backspace",
        "Control",
        "Meta",
        "Delete",
        "Shift",
      ];
      if (event.key == "Enter" && this.multiline == false)
        return event.preventDefault();
      if (
        this.maxCharacters &&
        (event.target as HTMLElement).innerText.length >= this.maxCharacters &&
        !allowedKeys.includes(event.key) &&
        !((event.metaKey || event.ctrlKey) && event.key == "a")
      )
        return event.preventDefault();
    },
    onPaste(event: ClipboardEvent) {
      event.preventDefault();
      let text = event.clipboardData?.getData("text/plain") || "";
      if (
        this.maxCharacters &&
        this.modelValue.toString().length + text.length > this.maxCharacters
      )
        text = text.substring(
          0,
          this.maxCharacters - this.modelValue.toString().length
        );
      document.execCommand("insertHTML", false, text);
    },
    onChange(event: InputEvent) {
      const target = event.target as HTMLElement;
      const value = target.innerHTML;
      this.hasValue = value.toString().length > 0;
      this.$emit("update:modelValue", value);
      this.$emit("update:text", target.innerText);
    },
  },
});
