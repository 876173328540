
import { defineComponent } from "vue";
import { MdSidebar, IMenuItem, ISidebarFAB } from "@/components/md/MdSidebar";

export default defineComponent({
  name: "InboxSettingsSidebar",
  components: {
    MdSidebar,
  },
  data() {
    const prefix = "/inbox/" + this.$route.params.chatbotId;
    return {
      menu: [
        {
          icon: "bolt",
          title: "Schnellantworten",
          href: prefix + "/settings/answers",
        },
        {
          icon: "filter_alt",
          title: "Individuelle Ansichten",
          href: prefix + "/settings/views",
        },
        {
          icon: "tune",
          title: "Individuelle Felder",
          href: prefix + "/settings/fields",
        },
        {
          icon: "label",
          title: "Tags",
          href: prefix + "/settings/tags",
        },
        {
          divider: true,
        },
        {
          icon: "arrow_backward",
          title: "Posteingang",
          href: prefix,
        },
      ] as IMenuItem[],
    };
  },
});
