
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "MdSnackbar",
  props: {
    content: {
      type: String,
      required: true,
    },
    state: {
      type: String as PropType<"info" | "success" | "error">,
    },
  },
});
