
import { defineComponent } from "vue";
import { MdCard } from "@/components/md/MdCard";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { UserStore } from "@/store/constants";
import { signOut } from "@/services/AuthService";

export default defineComponent({
  name: "UserProfile",
  components: {
    MdCard,
    MdButton,
  },
  setup() {
    const store = useStore();
    const user = store.getters[UserStore.Getters.USER];
    return {
      store,
      user,
      signOut,
    };
  },
  data() {
    return {
      organization: "test",
    };
  },
});
