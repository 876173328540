
import { useStore } from "@/store";
import { RootStore } from "./store/constants";
import { MdSnackbar, MdSnackbarWrapper } from "@/components/md/MdSnackbar";
import { TransitionFade } from "@/components/cc/Transition";
import VariablePicker from "@/components/bot/VariablePicker.vue";
import UniPickerTransition from "@/components/bot/UniPickerTransition.vue";
import Loader from "@/components/Loader.vue";

var snackbarTimeoutId;
var store;
export default {
  components: {
    MdSnackbar,
    MdSnackbarWrapper,
    VariablePicker,
    UniPickerTransition,
    Loader,
    TransitionFade,
  },
  setup() {
    store = useStore();
  },
  computed: {
    sidenavRail() {
      return store.getters[RootStore.Getters.SIDENAV_RAIL];
    },
    snackbar() {
      return store.getters[RootStore.Getters.GET_SNACKBAR];
    },
    variablePicker() {
      return store.getters[RootStore.Getters.GET_VARIABLE_PICKER];
    },
    showLoader() {
      return store.getters[RootStore.Getters.IS_LOADER_SHOWN];
    },
  },
  watch: {
    snackbar(newValue) {
      if (newValue) {
        if (snackbarTimeoutId) clearTimeout(snackbarTimeoutId);
        snackbarTimeoutId = setTimeout(() => {
          store.commit(RootStore.Mutations.REMOVE_SNACKBAR);
        }, 3200);
      }
    },
  },
};
